import { memo, useEffect } from 'react';
import StatusComponent from './StatusComponent';
import { PAGE_TITLE } from '../../constants/page';

const PaginaOffline: React.FC = memo(() => {
  useEffect(() => {
    document.title = `${PAGE_TITLE} | No disponible en modo offline`;
  }, []);

  return (
    <StatusComponent
      status="500"
      title="Oops!"
      subTitle="No puedes acceder a esta pantalla en modo offline."
    />
  );
});

PaginaOffline.displayName = 'PaginaOffline';

export default PaginaOffline;
