import React, { ReactNode, useMemo } from 'react';

import Footer from '../components/Footer';
import ProLayout from '@ant-design/pro-layout';
import { UserOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import RightContent from '../components/RightContent';
import { NotificationBell } from '../components/Notifications';

// Redux
import { useAppSelector } from '../../../store/store';

// Utils
import { isActionableForUser } from '../../../utils/validateRole';

// Constants
import { LOCAL_MODE } from '../../../config';
import { PRIMARY_COLOR } from '../../../constants/colors';
import { PERMISSIONS } from '../../../constants/permissions';
import { ROUTES } from '../../../constants/routes-constants';

// Images
import logo from '../../../assets/images/logo_full.png';
import { useWindowSize } from '../../../utils/useWindowSize';

// Interfaces
interface LayoutProps {
  children: ReactNode;
  isWhiteLayout?: boolean;
}

const contentStyle = { margin: 0, padding: 0 } as const;

const LoginButton = (
  <Link
    style={{
      padding: 0,
      color: 'black',
      fontWeight: 500,
      paddingLeft: 10,
      paddingRight: 10,
      fontSize: '0.9rem'
    }}
    to={ROUTES.LOGIN_ROUTE}
    type="text"
    key="login"
  >
    Ingresar
  </Link>
);

export const Layout: React.FC<LayoutProps> = (props) => {
  const location = useLocation();
  const user = useAppSelector((state) => state.auth.user);

  const { width } = useWindowSize();
  const isMobile = width && width <= 767;

  const actions = useMemo(() => {
    return user
      ? isActionableForUser(user, [PERMISSIONS.COMUNICACIONES_EMAILS_SELF_LIST]) && !LOCAL_MODE
        ? [<NotificationBell key="notification" />]
        : []
      : [LoginButton];
  }, [user]);

  return (
    <ProLayout
      logo={logo}
      layout="top"
      fixSiderbar
      fixedHeader
      title={false}
      menuRender={false}
      location={location}
      contentStyle={contentStyle}
      style={{ height: '100vh' }}
      actionsRender={() => actions}
      footerRender={() => <Footer />}
      collapsedButtonRender={() => null}
      className={props.isWhiteLayout ? 'white-layout' : ''}
      avatarProps={
        user
          ? {
              size: 'small',
              icon: <UserOutlined />,
              style: { backgroundColor: PRIMARY_COLOR },
              title: isMobile ? undefined : `${user.nombre} ${user.apellido}`,
              render: (props, dom) => {
                return <RightContent dom={dom} />;
              }
            }
          : undefined
      }
      menuHeaderRender={(logoDom) => (
        <div style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
          <Link to="/">{logoDom}</Link>
        </div>
      )}
      token={{
        header: { colorBgHeader: 'white', colorTextMenu: PRIMARY_COLOR }
      }}
    >
      {props.children}
    </ProLayout>
  );
};

export default Layout;
