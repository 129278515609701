import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  user: {
    email: string;
    password: string;
  };
} = { user: { email: '', password: '' } };

const offlineSlice = createSlice({
  name: 'offline',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<{ email: string; password: string }>) {
      state.user = action.payload;
    }
  }
});

export const offlineActions = { ...offlineSlice.actions };

export default offlineSlice.reducer;
