/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';

import { Button } from 'antd';
import { AuthLayout } from '../components/layout';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { ValidateEmailModal } from './components/validateEmailModal';
import ProForm, { ProFormInstance, ProFormText } from '@ant-design/pro-form';

// Services
import AuthService from '../../../services/auth.service';

// Constants
import { LOCAL_MODE } from '../../../config';
import { PAGE_TITLE } from '../../../constants/page';
import { ROUTES } from '../../../constants/routes-constants';

// Interfaces
import type { LoginParams } from './interfaces';

// Css
import './styles.scss';

// Redux
import { useAppDispatch } from '../../../store/store';
import { offlineActions } from '../../../store/slices/offline';

export const LoginPage: FC = memo(() => {
  const ref = useRef<ProFormInstance<LoginParams>>(undefined);

  const dispatch = useAppDispatch();
  const [validateModalOpen, setValidateModalOpen] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setSeachParams] = useSearchParams();

  const eventRedirect = searchParams.get('eventRedirect');

  useEffect(() => {
    if (window.location.pathname.includes(ROUTES.VALIDATE_EMAIL_ROUTE)) {
      setValidateModalOpen(true);
    }
    document.title = `${PAGE_TITLE} | Iniciar sesión`;
  }, []);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        ref.current?.submit();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  const onSubmit = useCallback(
    async (data: LoginParams) => {
      const onLogin = LOCAL_MODE
        ? AuthService.syncRemoteUser(data.email.trim(), data.password)
        : AuthService.login(data.email.trim(), data.password);

      const onRedirect = () => {
        if (eventRedirect) {
          navigate(
            `${ROUTES.EVENT_DETAILS_ROUTE.replace(':id', eventRedirect)}?acreditacion_request=true`
          );
        } else {
          navigate('/');
        }
        if (LOCAL_MODE) {
          dispatch(offlineActions.setUser({ email: data.email.trim(), password: data.password }));
        }
        return true;
      };

      const onError = (e: any) => {
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.message === 'El usuario no esta registrado'
        ) {
          ref.current?.setFields([{ name: 'email', errors: ['Email o contraseña incorrecta'] }]);
        }

        return false;
      };

      return await onLogin.then(onRedirect).catch(onError);
    },
    [dispatch, eventRedirect, navigate]
  );

  return (
    <AuthLayout>
      <ValidateEmailModal open={validateModalOpen} onCancel={() => setValidateModalOpen(false)} />

      <ProForm
        formRef={ref}
        onFinish={onSubmit}
        style={{ width: '100%' }}
        submitter={{
          render(props) {
            return (
              <div className="login-page-footer">
                <div>
                  {LOCAL_MODE ? null : (
                    <div style={{ marginTop: -10, marginBottom: 10 }}>
                      <Link
                        style={{
                          float: 'right',
                          color: '#595959',
                          marginBottom: 20
                        }}
                        to="/olvide-contraseña"
                      >
                        Olvidé mi contraseña
                      </Link>
                    </div>
                  )}

                  <Button
                    shape="round"
                    {...props.submitButtonProps}
                    style={{ width: '100%' }}
                    type="primary"
                    size="large"
                    onClick={props.submit}
                  >
                    Ingresar
                  </Button>

                  <Button
                    size="large"
                    shape="round"
                    disabled={LOCAL_MODE}
                    style={{ marginTop: 10, width: '100%' }}
                    onClick={() => {
                      navigate('/registrarse');
                    }}
                  >
                    Registrarse
                  </Button>
                </div>
                <img
                  className="login-page-footer-logo"
                  alt="logo de la provincia de buenos aires"
                  src={`${process.env.PUBLIC_URL}/logo_gba.png`}
                />
              </div>
            );
          }
        }}
      >
        <ProFormText
          name="email"
          fieldProps={{
            size: 'large',
            type: 'email',
            style: { borderRadius: 20 },
            prefix: <UserOutlined className={'prefixIcon'} />
          }}
          placeholder={'Email'}
          normalize={(value) => value.trim()}
          rules={[
            {
              required: true,
              message: 'Debe completar este campo'
            },
            {
              type: 'email',
              message: 'Debe ingresar un email válido'
            }
          ]}
        />
        <ProFormText.Password
          name="password"
          fieldProps={{
            size: 'large',
            style: { borderRadius: 20 },
            prefix: <LockOutlined className={'prefixIcon'} />
          }}
          placeholder={'Contraseña'}
          rules={[
            {
              required: true,
              message: 'Debe completar este campo'
            }
          ]}
        />
      </ProForm>
    </AuthLayout>
  );
});

LoginPage.displayName = 'LoginPage';

export default LoginPage;
