import { FC } from 'react';
import { Layout } from '../layout';
import { Navigate, Outlet } from 'react-router-dom';

import type { IUser } from '../../interfaces/user';
import { LOCAL_MODE } from '../../config';

interface ProtectedRouteProps {
  user?: IUser;
  children: any;
  layout?: string;
  isAllowed: boolean;
  redirectPath?: string;
  disabledOffline?: boolean;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  user,
  layout,
  children,
  isAllowed,
  disabledOffline,
  redirectPath = '/403'
}) => {
  if (disabledOffline && LOCAL_MODE) {
    return <Navigate to="/offline" replace />;
  }

  if (!user) {
    return <Navigate to="/ingresar" replace />;
  }

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <Layout layout={layout}>{children}</Layout> : <Outlet />;
};
