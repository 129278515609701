import React, { Suspense } from 'react';
import { useAppSelector } from './store/store';
import { Route, Routes } from 'react-router-dom';
import { PageLoading } from '@ant-design/pro-layout';
import { unstable_HistoryRouter as Router } from 'react-router-dom';

// Styles
import './styles/main.scss';

// Components
import { Layout } from './components/layout';
import { ProtectedRoute } from './components/routes';
import ScrollToTop from './components/routes/scrollTop';

// Public Pages
import LoginPage from './pages/auth/login';
import { HomePage } from './pages/public/home';
import RegisterPage from './pages/auth/register';
import Error500Page from './pages/status/Pagina500';
import NotFoundPage from './pages/status/Pagina404';
import NotAllowedPage from './pages/status/Pagina403';
import ResetPassword from './pages/auth/resetPassword';
import ForgetPassword from './pages/auth/forgetPassword';

import EventDetailsPage from './pages/public/event/details';
import PreviousEventsPage from './pages/public/previousEvents';
import EventInscriptionPage from './pages/public/event/inscription';

// Events Pages
const NewEventPage = React.lazy(() => import('./pages/events/new'));
const EventsListPage = React.lazy(() => import('./pages/events/list'));
const EventDetailsAdminPage = React.lazy(() => import('./pages/events/details'));
const EventSchedulePage = React.lazy(() => import('./pages/public/event/schedule'));
const EventSelectionPage = React.lazy(() => import('./pages/public/event/selection'));
const AdminEventSelectionPage = React.lazy(() => import('./pages/selection/pages/selection'));

// Entities Pages
const EditEntityPage = React.lazy(() => import('./pages/entity/edit'));
const EntitiesListPage = React.lazy(() => import('./pages/entity/list'));
const RegisterEntityPage = React.lazy(() => import('./pages/entity/new'));

// Profile
const ProfilePage = React.lazy(() => import('./pages/profile/pages/myInformation'));

// Comunications
const ComunicationsNewPage = React.lazy(() => import('./pages/comunications/new'));
const ComunicationsListPage = React.lazy(() => import('./pages/comunications/list'));
const ComunicationsDetailsPage = React.lazy(() => import('./pages/comunications/details'));
const ComunicationsTemplatesListPage = React.lazy(() => import('./pages/comunications/templates'));
const ComunicationsNewTemplatesPage = React.lazy(() => import('./pages/comunications/newTemplate'));

// Constants
import { ROUTES } from './constants/routes-constants';
import { PERMISSIONS } from './constants/permissions';

// Utils
import { createBrowserHistory } from 'history';
import { isActionableForUser } from './utils/validateRole';

// Interfaces
import type { IUser } from './interfaces/user';
import PaginaOffline from './pages/status/PaginaOffline';

/** Rutas a las que se puede acceder sin necesidad de autenticarse. */
const publicRoutes = [
  { path: '/', element: <HomePage /> },
  { path: '*', element: <NotFoundPage />, hideLayout: true },
  { path: '/500', element: <Error500Page />, hideLayout: true },
  { path: '/403', element: <NotAllowedPage />, hideLayout: true },
  { path: '/offline', element: <PaginaOffline />, hideLayout: true },

  // Auth
  { path: ROUTES.LOGIN_ROUTE, element: <LoginPage />, hideLayout: true },
  { path: ROUTES.REGISTER_ROUTE, element: <RegisterPage />, hideLayout: true },
  { path: ROUTES.RESET_PASSWORD_ROUTE, element: <ResetPassword />, hideLayout: true },
  { path: ROUTES.FORGOT_PASSWORD_ROUTE, element: <ForgetPassword />, hideLayout: true },
  { path: ROUTES.VALIDATE_EMAIL_ROUTE, element: <LoginPage />, hideLayout: true },

  // Events
  { path: ROUTES.EVENT_DETAILS_ROUTE, element: <EventDetailsPage /> },
  { path: ROUTES.PREVIOUS_EVENTS_ROUTE, element: <PreviousEventsPage /> }
];

/** Rutas que requieren autenticación. */
const privateRoutes: {
  path: string;
  element: React.ReactElement;
  disabledOffline?: boolean;
  layout?: 'default' | 'white';
  isAllowed: (user?: IUser) => boolean;
}[] = [
  // User
  {
    path: ROUTES.PROFILE_ROUTE,
    element: <ProfilePage />,
    isAllowed: (user?: IUser) => !!user
  },

  // Entity
  {
    path: ROUTES.REGISTER_ENTITY_ROUTE,
    disabledOffline: true,
    element: <RegisterEntityPage />,
    isAllowed: (user?: IUser) => !!user
  },
  {
    path: ROUTES.ENTITIES_LIST_ROUTE,
    element: <EntitiesListPage />,
    isAllowed: (user?: IUser) => isActionableForUser(user, [PERMISSIONS.ENTIDADES_LIST])
  },
  {
    path: ROUTES.EDIT_ENTITY_ROUTE,
    element: <EditEntityPage />,
    isAllowed: (user?: IUser) =>
      isActionableForUser(user, [PERMISSIONS.ENTIDADES_LIST, PERMISSIONS.ENTIDADES_UPDATE])
  },
  {
    path: ROUTES.ADMIN_NEW_ENTITY_ROUTE,
    element: <RegisterEntityPage asAdmin />,
    isAllowed: (user?: IUser) =>
      isActionableForUser(user, [PERMISSIONS.ENTIDADES_LIST, PERMISSIONS.ENTIDADES_CREATE])
  },

  // Events
  {
    path: ROUTES.EVENTS_LIST_ROUTE,
    element: <EventsListPage />,
    isAllowed: (user?: IUser) => isActionableForUser(user, [PERMISSIONS.EVENTOS_LIST])
  },
  {
    path: ROUTES.NEW_EVENT_ROUTE,
    disabledOffline: true,
    element: <NewEventPage />,
    isAllowed: (user?: IUser) => isActionableForUser(user, [PERMISSIONS.EVENTOS_CREATE])
  },
  {
    path: ROUTES.EDIT_EVENT_ROUTE,
    element: <EventDetailsAdminPage />,
    isAllowed: (user?: IUser) =>
      isActionableForUser(user, [PERMISSIONS.EVENTOS_LIST, PERMISSIONS.EVENTOS_UPDATE])
  },
  {
    element: <EventInscriptionPage />,
    path: ROUTES.EVENT_INSCRIPTION_ROUTE,
    isAllowed: (user?: IUser) => isActionableForUser(user, [PERMISSIONS.INSCRIPCIONES_SELF_CREATE])
  },
  {
    element: <EventSelectionPage />,
    path: ROUTES.EVENT_SELECTION_ROUTE,
    isAllowed: (user?: IUser) =>
      isActionableForUser(user, [PERMISSIONS.INSCRIPCIONES_SELF_REUNIONES_DESEADAS])
  },
  {
    element: <EventSchedulePage />,
    path: ROUTES.EVENT_SCHEDULE_ROUTE,
    isAllowed: (user?: IUser) => isActionableForUser(user, [PERMISSIONS.REUNIONES_SELF_LIST])
  },
  {
    element: <AdminEventSelectionPage />,
    path: ROUTES.EDIT_EVENT_SELECTION_ROUTE,
    isAllowed: (user?: IUser) =>
      isActionableForUser(user, [PERMISSIONS.INSCRIPCIONES_REUNIONES_DESEADAS])
  },

  // Comunications
  {
    disabledOffline: true,
    element: <ComunicationsListPage />,
    path: ROUTES.COMUNICATIONS_LIST_ROUTE,
    isAllowed: (user?: IUser) => isActionableForUser(user, [PERMISSIONS.COMUNICACIONES_LIST])
  },
  {
    disabledOffline: true,
    element: <ComunicationsNewPage />,
    path: ROUTES.COMUNICATIONS_NEW_ROUTE,
    isAllowed: (user?: IUser) => isActionableForUser(user, [PERMISSIONS.COMUNICACIONES_CREATE])
  },
  {
    disabledOffline: true,
    element: <ComunicationsTemplatesListPage />,
    path: ROUTES.COMUNICATIONS_TEMPLATES_ROUTE,
    isAllowed: (user?: IUser) =>
      isActionableForUser(user, [PERMISSIONS.COMUNICACIONES_TEMPLATE_LIST])
  },
  {
    disabledOffline: true,
    element: <ComunicationsNewTemplatesPage />,
    path: ROUTES.COMUNICATIONS_NEW_TEMPLATE_ROUTE,
    isAllowed: (user?: IUser) =>
      isActionableForUser(user, [PERMISSIONS.COMUNICACIONES_TEMPLATE_CREATE])
  },
  {
    disabledOffline: true,
    element: <ComunicationsNewTemplatesPage />,
    path: ROUTES.COMUNICATIONS_EDIT_TEMPLATE_ROUTE,
    isAllowed: (user?: IUser) =>
      isActionableForUser(user, [PERMISSIONS.COMUNICACIONES_TEMPLATE_UPDATE])
  },
  {
    disabledOffline: true,
    element: <ComunicationsDetailsPage />,
    path: ROUTES.COMUNICATIONS_EDIT_ROUTE,
    isAllowed: (user?: IUser) =>
      isActionableForUser(user, [
        PERMISSIONS.COMUNICACIONES_READ,
        PERMISSIONS.COMUNICACIONES_UPDATE
      ])
  }
];

export const history = createBrowserHistory();

const RootComponent: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);

  return (
    // @ts-ignore
    <Router history={history}>
      <Routes>
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              route.hideLayout ? (
                route.element
              ) : (
                <Layout>
                  <ScrollToTop />
                  {route.element}
                </Layout>
              )
            }
          />
        ))}

        {privateRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute
                user={user!}
                layout={route.layout}
                isAllowed={route.isAllowed(user!)}
                disabledOffline={route.disabledOffline}
              >
                <Suspense fallback={<PageLoading />}>
                  <ScrollToTop />
                  {route.element}
                </Suspense>
              </ProtectedRoute>
            }
          />
        ))}
      </Routes>
    </Router>
  );
};

export default RootComponent;
